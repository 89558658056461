import * as React from "react"
import { useEffect, useState } from "react"
import PageWithoutHero from "../../../components/PageWithoutHero"
// import "antd/dist/antd.css"
import BuildEditor from "../../../components/BuildSelector/BuildEditor"

const EditBuild = ({ id = '' }) =>
  (
    <PageWithoutHero>
      <h2>Build Selector</h2>
      <p>Use the form below to select the finishes for your new home. Any upgrades discussed as part
        of your original contract will be reflected here. Each phase has a due date after which you
        will not be able to edit the selections from this form.</p>
      <p>If you have any questions, or would like to select an option not shown on this form, please
        contact us via the WhatsApp group.</p>
      <BuildEditor id={id.toUpperCase()}/>
    </PageWithoutHero>
  )

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default EditBuild
